import Amplify, { Auth } from 'aws-amplify';
import envConfig from 'utils/config';

const config = {
	Auth: {
		    userPoolId: envConfig.cognitoUserPoolId || process.env.REACT_APP_COGNITO_USER_POOL_ID,
		    region: envConfig.cognitoRegion || process.env.REACT_APP_COGNITO_REGION,
		    userPoolWebClientId: envConfig.cognitoWebClientId || process.env.REACT_APP_COGNITO_WEB_CLIENT_ID
		    }	
};

Amplify.configure(config);

export const loginAuthServices = async ({ userName, password }) => {
  try {
    const user = await Auth.signIn(userName, password);
    return user;
  }
  catch (error) {
    // An AWS Cognito lambda is setup as a pre-authorization event, which returns lockout information to the user. AWS is adding extra text in front. Remove it. 
    if (error?.message) {
      error.message = error.message.replace("PreAuthentication failed with error ", "");
    }

    return error;
  }
};

export const registerAuthServices = async ({ username, password }) => {
	try {
		const { user } = await Auth.signUp({
			username: username,
			password,
			attributes: {
				email: username
			},
		});

		return user;
	}
	catch (error) {
		return error;
	}
};

export const forgotPassword = async ({ username }) => {
	const data = await Auth.forgotPassword(username);
};

export const forgotPasswordValidation = async ({ username, code, password }) => {
	const data = await Auth.forgotPasswordSubmit(username, code, password);
};

export const logout = async () => {
	try {
		await Auth.signOut();
		window.location.href = "/";
	}
	catch (error) {
		console.log(`Error signing out`, error);
	}
};

export const resendSignUpAuthServices = async userName => {
	try {
	  const user = await Auth.resendSignUp(userName);
	  return user;
	}
	catch (error) {
	  return error;
	}
  };

  export const changePassword = async ({ oldPassword, newPassword }) => {
	try {
		const user = await Auth.currentAuthenticatedUser();
		const data = await Auth.changePassword(user, oldPassword, newPassword);
		return data;
	}
	catch (error) {
		return error;
	}
};

