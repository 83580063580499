import { takeLatest, call, put, fork } from 'redux-saga/effects';
import * as actionType from 'actions';
import * as services from 'services';

function* getJobDetailsSaga(jobInfo) {
	try {
		const response = yield call(services.JobDetailsServices, jobInfo.payload);
		yield put(actionType.getJobDetailsPostSuccess(response.data));
	}
	catch (e) {
		yield put(
			actionType.commonError({
				error: e?.message?.toString()
			})
		);
	}
}

function* watchGetJobDetailsSaga() {
	yield takeLatest(actionType.GET_JOB_DETAILS, getJobDetailsSaga);
}

function* loginAuthPostSaga(loginInfo) {
	try {
		const response = yield call(services.loginAuthServices, loginInfo.payload);

		if (response.code !== undefined) { // If the code is set, then Cognito has thrown an error back ot us (e.g. NotAuthorized)
			yield put(
				actionType.commonError({
					error: response.message?.toLowerCase()?.includes('incorrect username or password')
						? `Incorrect username or password. Please try again.
							 Your account will be locked after 5 attempts. You can also try Forgot Password or contact support for assistance.`
						: response?.message
				})
			);
		} else {
			yield put(actionType.loginAuthPostSuccess(response));
		};
	}
	catch (e) {
		yield put(
			actionType.commonError({
				error: "We're sorry, we encountered an error while logging in. Please try again."
			})
		);
	}
}

function* watchLoginAuthPostSaga() {
	yield takeLatest(actionType.AUTH_LOGIN_POST, loginAuthPostSaga);
}

function* getExistingUserSaga(data) {
	try {
		const response = yield call(services.getExistingUserServices, data.payload);
		yield put(actionType.getExistingUserSuccess(response.data));
	}
	catch (e) {
		yield put(actionType.commonError({
			error: 'Something went wrong'
		}));
	}
}

function* watchExistingUserSaga() {
	yield takeLatest(actionType.GET_EXISTING_USER, getExistingUserSaga);
}

function* registerAuthPostSaga(registerInfo) {
	try {
		const response = yield call(services.registerAuthServices, registerInfo.payload);
		if (response !== undefined && response !== null &&
			(response.code === "UsernameExistsException" || response.authenticationFlowType === "USER_SRP_AUTH")) {
			yield put(actionType.registerAuthPostSuccess(response));
		}
		else {
			yield put(actionType.registerAuthPostSuccess(response.data));
		}
	}
	catch (e) {
		yield put(actionType.commonError({ error: 'Something went wrong' }));
	}
}

function* watchRegisterAuthPostSaga() {
	yield takeLatest(actionType.AUTH_REGISTER_POST, registerAuthPostSaga);
}

function* forgotPasswordAuthPostSaga(forgotPasswordInfo) {
	try {
		const response = yield call(services.forgotPassword, forgotPasswordInfo.payload);
		yield put(actionType.forgotPasswordAuthPostSuccess(response));
	}
	catch (e) {
		yield put(
			actionType.commonError({
				error: e?.message
			})
		);
	}
}

function* watchForgotPasswordAuthPostSaga() {
	yield takeLatest(actionType.AUTH_FORGOT_PASSWORD_POST, forgotPasswordAuthPostSaga);
}

function* forgotPasswordValidationSaga(forgotPasswordValidationInfo) {
	try {
		const response = yield call(services.forgotPasswordValidation, forgotPasswordValidationInfo.payload);
		yield put(actionType.forgotPasswordValidationSuccess(response))
	}
	catch (e) {
		yield put(
			actionType.commonError({
				error: e?.message
			})
		);
	}
}

function* watchForgotPasswordValidationSaga() {
	yield takeLatest(actionType.AUTH_FORGOT_PASSWORD_VALIDATION, forgotPasswordValidationSaga);
}

function* logoutSaga() {
	try {
		yield call(services.logout);
		yield put(actionType.logoutSuccess);
	}
	catch (e) {
		yield put(
			actionType.commonError({
				error: e?.message
			})
		);
	}
}

function* watchLogoutSaga() {
	yield takeLatest(actionType.AUTH_LOGOUT_POST, logoutSaga);
}

function* addApplicantSaga(request) {
	try {
		const response = yield call(services.addApplicantServices, request.payload);
		yield put(actionType.addApplicantSuccess(response));
		yield put(actionType.GetHeaderDetails(response.headers));
	}
	catch (e) {
		yield put(
			actionType.commonError({
				error: e?.message
			})
		);
	}
}

function* watchAddApplicantSaga() {
	yield takeLatest(actionType.ADD_APPLICANT, addApplicantSaga);
}

function* addRegisteredUserSaga(request) {
	try {
		const response = yield call(services.addRegisteredUserServices, request.payload);
		yield put(actionType.addRegisteredUserSuccess(response));
	}
	catch (e) {
		yield put(
			actionType.commonError({
				error: e?.message
			})
		);
	}
}

function* watchRegisteredUserSaga() {
	yield takeLatest(actionType.ADD_REGISTERED_USER, addRegisteredUserSaga);
}

function* resendSignUpAuthPostSaga(resendSignUpInfo) {
	try {
		const response = yield call(services.resendSignUpAuthServices, resendSignUpInfo.payload);
		if (response?.CodeDeliveryDetails) {
			yield put(actionType.resendSignUpAuthPostSuccess(response));
		} else {
			yield put(actionType.resendSignUpAuthPostFailed(response));
		}
	}
	catch (e) {
		yield put(actionType.commonError({ error: 'Something went wrong' }));
	}
}

function* watchResendSignUpAuthPostSaga() {
	yield takeLatest(actionType.AUTH_RESEND_SIGN_UP_POST, resendSignUpAuthPostSaga);
}

function* passwordUpdateAuthPostSaga(passwordUpdateInfo) {
	try {
		const response = yield call(services.changePassword, passwordUpdateInfo.payload);
		yield put(actionType.passwordUpdateAuthPostSuccess(response));
	}
	catch (e) {
		yield put(
			actionType.commonError({
				error: e?.message
			})
		);
	}
}
function* watchPasswordUpdateAuthPostSaga() {
	yield takeLatest(actionType.AUTH_PASSWORD_UPDATE_POST, passwordUpdateAuthPostSaga);
}
const auth = [
	fork(watchGetJobDetailsSaga),
	fork(watchLoginAuthPostSaga),
	fork(watchRegisterAuthPostSaga),
	fork(watchForgotPasswordAuthPostSaga),
	fork(watchForgotPasswordValidationSaga),
	fork(watchLogoutSaga),
	fork(watchAddApplicantSaga),
	fork(watchExistingUserSaga),
	fork(watchRegisteredUserSaga),
	fork(watchResendSignUpAuthPostSaga),
	fork(watchPasswordUpdateAuthPostSaga),
];

export default auth;